import Redacao from "@/interfaces/Redacao";

const RedacaoColumn = [
  {
    name: "actions",
    align: "center",
    label: "Ações",
    field: "",
    sortable: false,
  },
  {
    name: "titulo",
    align: "left",
    label: "Título",
    field: "titulo",
    sortable: true,
  },
  {
    name: "disciplina",
    align: "left",
    label: "Disciplina",
    field: (row: Redacao) => row.disciplina.descricao,
    sortable: true,
  },
  {
    name: "created_at",
    align: "center",
    label: "Data Cadastro",
    field: (row: Redacao) => row.created_at,
    sortable: true,
  },
];

export default RedacaoColumn;
